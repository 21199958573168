/* COLORS */
:root {
  --color-black: #000;
  --color-blue: #0097ff;
  --color-light-blue: #00d4ff;
  --color-background: #fff;
  --color-white: #fff;
  --color-input-bg: #d9d9d9;
  --color-input-border: #b3b3b3;
  --color-error: #ff0000;
}

a {
  color: var(--color-blue);
  text-decoration: none;

  &:visited,
  &:hover {
    color: var(--color-blue);
  }
}

/* RESET CSS */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture,
svg,
video,
canvas {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  background: none;
  border: none;
  color: inherit;
  text-transform: inherit;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}

body,
html {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;

  @media screen and (min-width: 2300px) {
    font-size: 20px;
  }

  @media screen and (min-width: 3200px) {
    font-size: 32px;
  }
}
