@import '../../styles/mixins.scss';

.footer {
  background-color: #7e4bfe;
  width: 100%;
  height: 160px;
  color: #fff;
  font-size: 1.2rem;
  z-index: 1;

  @include mobile {
    height: 60px;
    font-size: 1rem;
  }
}
