@import 'src/styles/mixins.scss';

.header {
  grid-area: header;
  background-color: var(--color-blue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 2rem;

  @include mobile {
    padding: 0.5rem 0.8rem;
    justify-content: space-between;
  }
}

.logo {
  width: 3.2rem;
  display: none;

  @include mobile {
    display: block;
  }
}

.logout {
  width: 1.875rem;
}
