@import '../../styles/mixins.scss';

$padding-top: 6%;
$padding-top-small: 0%;

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 18.75rem;
}

.error {
  color: var(--color-error);
  font-size: 1.2rem;
  margin-top: 1rem;
}

.body {
  height: 100%;
  width: 100%;
  padding: 4rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url('../../images/login-bg.png');
  background-size: cover;

  @include mobile {
    align-items: center;
    background-image: url('../../images/login-bg-mobile.png');
  }
}

.canario {
  background-image: url('../../images/professor-canario.png');
  background-size: contain;
  background-repeat: no-repeat;
  display: none;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;
  transform: translateX(10px);
  top: calc($padding-top + 8.2rem);

  @include mobile {
    display: block;
  }

  @include small-mobile {
    top: calc($padding-top-small + 8.2rem);
  }
}

.floatingImage {
  background-image: url('../../images/prroffessorres.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 1;
  position: absolute;
  bottom: 0;

  height: 62.5rem;
  width: 37.5rem;

  margin-right: 30%;

  @include tablet {
    margin-right: 40%;
  }

  @include mobile {
    width: 200px;
    height: 300px;

    margin-right: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: $padding-top;

    @include small-mobile {
      top: $padding-top-small;
    }
  }
}

.loginForm {
  margin-left: 40%;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem 3rem;
  border-radius: 25px;
  z-index: 2;
  position: absolute;

  button {
    margin-top: 1rem;
    width: 18rem;
  }

  @include tablet {
    margin-left: 50%;
  }

  @include mobile {
    transform: scale(0.8);
    margin-left: 0;
    top: calc($padding-top + 8.4rem);
  }

  @include small-mobile {
    transform: scale(0.7);
    top: calc($padding-top-small + 7.4rem);
  }
}
