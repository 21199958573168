.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99;
}

.container {
  display: none;

  &.open {
    display: block;
  }
}

.modal {
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  h1 {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
    color: var(--color-blue);
  }

  P {
    padding: 0 1rem;
    font-size: 1.2rem;
  }

  .actions {
    margin-top: 0.8rem;
    width: 100%;
    transform: scale(0.8);
    display: flex;
    justify-content: space-between;
  }
}
