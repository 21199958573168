@import '../../styles/mixins.scss';

.container {
  margin-top: 2rem;
  text-align: center;

  label {
    font-size: 1.2rem;
    font-weight: 600;
    color: #707070;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      cursor: pointer;
      width: fit-content;
      margin-left: 0.2rem;
      margin-top: 0.1rem;
    }

    img {
      width: 1.75rem;
    }
  }
}

.hint {
  transition: 0.4s opacity ease-in-out;
  font-size: 0.6rem;
  font-weight: 600;
  color: #707070;
  margin-top: 0.5rem;
  background-color: #d9d9d9;
  padding: 0.6rem;
  text-transform: uppercase;
  text-align: left;
  position: absolute;
  right: 0;
  z-index: 3;
  transform: translateX(67%) translateY(-52%);
  border-radius: 9px;
  width: 13rem;
  display: none;

  &.visible {
    display: block;
  }

  @include tablet {
    transform: translateX(0) translateY(-130%);
  }

  &::after {
    content: '';
    position: absolute;
    right: 93%;
    top: 25%;
    width: 2rem;
    height: 2rem;
    z-index: -1;

    background-image: url('../../images/hint-arrow-left.svg');
    background-repeat: no-repeat;
    background-position: top right;
    clear: both;

    @include tablet {
      background-image: url('../../images/hint-arrow-down.svg');

      top: 83%;
      left: 38%;
    }
  }
}

.input {
  display: block;
  margin-top: 1rem;
  text-transform: uppercase;

  /* Rectangle 3 */

  font-weight: 600;
  height: 4rem;
  width: 18rem;

  border: none;
  background: var(--color-input-bg);
  color: #383838;
  opacity: 0.5;
  border-radius: 25px;
  padding: 0.5rem 2rem;
  font-size: 1.2rem;
  text-align: center;

  // @media (max-width: 768px) {
  //   height: 3rem;
  // }

  &:focus {
    outline: none;

    box-shadow: inset 0 0 0 2px var(--color-input-border);
  }
}
