@mixin newDetail {
  content: '';
  height: 150%;
  width: 2rem;
  background-color: var(--color-white);
  position: absolute;
  left: 10%;
  top: -22%;
  transform: rotate(30deg);
}

.item {
  cursor: pointer;
  border: 2px solid var(--color-white);
  border-bottom-width: 0;
  color: var(--color-white);
  background-color: var(--color-light-blue);
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;

  button {
    width: 100%;
    height: 100%;
    padding: 1.8rem;
  }

  &.selected {
    background-color: #6ae1f9;
  }

  &.visited {
    background-color: #9d9d9d;
  }

  &.new {
    background-color: #ff7547;
    position: relative;
    overflow: hidden;

    &::after {
      @include newDetail;
    }

    &::before {
      @include newDetail;
      left: 27%;
      width: 0.6rem;
    }
  }
}

$thumbWidth: 11rem;
$thumbHeight: calc($thumbWidth / 1.4);

.itemMobile {
  &.selected {
    background-color: #6ae1f94e;
  }

  button {
    width: 100%;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
  }

  .thumb {
    border-radius: 25px;
    width: $thumbWidth;
    height: $thumbHeight;
    background-color: var(--color-white);
    position: relative;

    img {
      border-radius: 25px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.watched::after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 3rem;
      border-radius: 25px;
      right: 0;
      bottom: 0;
      transform: translate(25%, 25%);
      background-size: contain;
      background-image: url('../../images/check-icon.svg');
    }
  }

  .info {
    text-align: left;
    flex: 1;
    color: var(--color-white);
    margin-left: 1rem;
    padding: 0 0.5rem;
    text-transform: uppercase;

    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    .subtitle {
      margin-top: 0.5rem;
      font-size: 0.7rem;
    }
  }
}
