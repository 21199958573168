.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--color-white);

  .content {
    min-width: 25rem;
    max-width: 50rem;
    width: 100%;
    margin-top: 3rem;

    h1 {
      font-size: 1.7rem;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
    }

    p {
      margin-top: 0.2rem;
      text-align: center;
      font-size: 1.1rem;
      font-weight: 400;
    }

    .video {
      margin-top: 2rem;
      width: 100%;
      height: 28.125rem;
      border-radius: 25px;
      background-color: #383838;
    }

    .actions {
      margin: 1rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.nextOnly {
        justify-content: flex-end;
      }

      &.prevOnly {
        justify-content: flex-start;
      }
    }
  }
}
