@import '../../styles/mixins.scss';

.mobileContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #007ee9;

  main {
    background: #383838;
    height: 14.2rem;
    width: 100%;
  }

  .menu {
    flex: 1;
    width: 100%;
    background: url('../../images/blue-bg-mobile.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    overflow: auto;
  }
}

.container {
  overflow: auto;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 18.75rem 1fr; // 200px for nav, rest for main content
  grid-template-rows: 4rem 1fr; // 3.5rem for header, rest for content below
  grid-template-areas:
    'header header'
    'nav main';

  background-image: url('../../images/blue-bg.png');

  background-color: #007ee9;
  background-size: cover;
  background-position: right bottom;

  .menu {
    grid-area: nav;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .logo {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      z-index: 10;
      width: 7.5rem;
    }

    .menuHeader {
      background-color: var(--color-blue);
      border: 2px solid var(--color-white);
      color: var(--color-white);
      font-weight: 600;
      font-size: 2rem;
      text-align: center;
      padding: 3.2rem 2rem;
    }

    .menuContent {
      padding-right: 0.2rem;
      flex-grow: 1;
      position: relative;
      overflow: auto;
      margin-top: 0.5rem;
      width: calc(100% + 0.8rem);
      height: 100px;

      /* Webkit browsers (Chrome, Safari) */
      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #f0f0f0; /* Change this to desired color */
        border-radius: 0.625rem;
        border: 0.3rem solid transparent;
      }

      /* Firefox */
      scrollbar-width: thin;
      scrollbar-color: #f0f0f0 transparent;

      /* Edge */
      &::-ms-scrollbar {
        width: 0.5rem;
      }

      &::-ms-scrollbar-track {
        background: transparent;
      }

      &::-ms-scrollbar-thumb {
        background-color: #f0f0f0; /* Change this to desired color */
        border-radius: 0.625rem;
        border: 0.3rem solid transparent;
      }
    }
  }

  main {
    grid-area: main;
    padding: 0 2rem;
  }
}
