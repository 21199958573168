@mixin touch {
  @media (hover: none) {
    @content;
  }
}

@mixin no-touch {
  @media (hover: hover) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1367px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin small-mobile {
  @media (max-width: 768px) {
    @media (max-height: 700px) {
      @content;
    }
  }
}
