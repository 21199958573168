@import '../../styles/mixins.scss';

.button {
  background-color: var(--color-light-blue);
  color: var(--color-white);
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 25px;
  border: none;
  transition: 0.3s all ease-in-out;
  text-transform: uppercase;

  @include no-touch {
    &:hover,
    &:active {
      background-color: #00b8d4;
      cursor: pointer;
    }
  }

  &.secondary {
    background-color: var(--color-white);
    color: #0097ff;

    @include no-touch {
      &:hover,
      &:active {
        // background-color: #def8ff;
      }
    }
  }
}
