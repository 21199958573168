@import '../../styles/mixins.scss';

.video {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background-color: var(--color-black);

  @include mobile {
    border-radius: 0;
  }
}
